import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container, Row, Col, Table} from "react-bootstrap"

class Gear extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Gear at Capernwray" />
        <Container>
          <Row>
            <Col sm={12}>
            <h1 className="page-title my-4">Gear Hire</h1>
            <h2 className="mb-4">We have an exceptional range of equipment to hire at Capernwray.</h2>
            <p>All our equipment is maintained to the highest standards and is serviced impeccably by our on-site IDEST centre, when required.</p> 
            <p>We suggest that you book your requirements in advance, preferably by giving us a ring on 01524 735132. This can help avoid disappointment during busy times. When you rent gear from us, we will retain your Capernwray card as security-please ensure you have it with you! You can rent a full day's package of equipment, either 'dry', i.e. a drysuit based package, or 'semi-dry' as the name suggests. Our current gear hire prices are shown below.</p>
            </Col>
          </Row>
          <Row>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Item</th>
                  <th>Price per day (£)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    FULL SET OF GEAR (DRY)
                    (Cylinder, reg + Octo + gauges, BC, dry suit, under suit, mask, fins, snorkel, hood, gloves, weights)
                  </td>
                  <td>
                    60.00
                  </td>
                </tr>
                <tr>
                  <td>
                    FULL SET OF GEAR (SEMI-DRY)
                    (Cylinder, reg, octo + gauges, BC, semidry, boots, mask, fins, snorkel, hood, gloves, weights)
                  </td>
                  <td>
                    50.00
                  </td>
                </tr>
                <tr>
                  <td>
                    Dry Suit
                  </td>
                  <td>
                    40.00
                  </td>
                </tr>
                <tr>
                  <td>
                    Undersuit
                  </td>
                  <td>
                    10.00
                  </td>
                </tr>
                <tr>
                  <td>
                    Cylinder (incl. first fill)
                  </td>
                  <td>
                    12
                  </td>
                </tr>
                <tr>
                  <td>
                    Regulator incl. Gauges and Octopus
                  </td>
                  <td>
                    15
                  </td>
                </tr>
                <tr>
                  <td>
                    BCD incl. direct feed
                  </td>
                  <td>
                    10
                  </td>
                </tr>
                <tr>
                  <td>
                    Semi Dry Suit incl. attached hood and boots
                  </td>
                  <td>
                    15
                  </td>
                </tr>
                <tr>
                  <td>
                    Hood
                  </td>
                  <td>
                    3
                  </td>
                </tr>
                <tr>
                  <td>
                    Mask
                  </td>
                  <td>
                    3
                  </td>
                </tr>
                <tr>
                  <td>
                    Snorkel
                  </td>
                  <td>
                    1.50
                  </td>
                </tr>
                <tr>
                  <td>
                    Fins
                  </td>
                  <td>
                    5
                  </td>
                </tr>
                <tr>
                  <td>
                    Boots
                  </td>
                  <td>
                    3
                  </td>
                </tr>
                <tr>
                  <td>
                    Gloves
                  </td>
                  <td>
                    3
                  </td>
                </tr>
                <tr>
                  <td>
                    Weight Belt incl. weights
                  </td>
                  <td>
                    5
                  </td>
                </tr>
                <tr>
                  <td>
                    WEIGHTS (without belt – up to 24lbs)
                  </td>
                  <td>
                    4
                  </td>
                </tr>
                <tr>
                  <td>
                    Torches (+ batteries)
                  </td>
                  <td>
                    5
                  </td>
                </tr>
                <tr>
                  <td>
                    Ankle Weights
                  </td>
                  <td>
                    3
                  </td>
                </tr>
                <tr>
                  <td>
                    LP hose (Seatech fitting)
                  </td>
                  <td>
                    3
                  </td>
                </tr>
                <tr>
                  <td>
                  LP hose (Apeks fitting)
                  </td>
                  <td>
                    3
                  </td>
                </tr>

              </tbody>
            </Table>
            <p>
            *Diving in a dry suit requires specialist training and Capernwray reserve the right to refuse hire to those individuals who in our opinion do not possess the requisite level of knowledge or experience in dry suit use.
            </p>
          </Row>
        </Container>
      </Layout>
    )
  }
}

export default Gear

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
